import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role, UserService } from '@swan/lib/profile';
import { ProductDetailsComponent } from 'app/modules/admin/product/comp/product-details/product-details.component';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class CanViewMainOrders 
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._userService.hasRoles([
            Role.ownerMarketRegionEdit,
            Role.productMarketEdit,
            Role.partnerMarketEdit,
            Role.orderMarketRead,
            Role.adminUi
        ]);
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanViewBrandOrders 
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._userService.hasRole(Role.productEdit) && !this._userService.hasRole(Role.admin);
    }
}
